.unreadIcon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}

.notification {
  width: 100%;
}

.notification__text {
  line-height: 22px;
  font-size: 16px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  //max-width: 200px;
  overflow: hidden;

  &_open {
    -webkit-line-clamp: unset;
  }
}