.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  .header {
    background: transparent;
  }
  .container {
    display: flex;
    margin-block: 52px;
    justify-content: center;
    align-items: center;
    .arrowLeftIcon {
      padding: 10px;
      background: transparent;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        background: rgba(0,0,0,0.05);
      }
    }
    .formContainer {
      max-width: 560px;
      width: 100%;
      flex-grow: 1;
      .title {
        line-height: 48px;
        letter-spacing: -0.8px;
      }
      .subTitle {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 16px;
      }
      .sectionTitle {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 8px;
      }
      .progressBar {
        margin-bottom: 48px;
      }
      .link{
        padding: 0 0 24px 0
      }
      .hintContainer {
        .icon {
          width: 22px;
          height: 22px;
        }
        .hintTitle {
          margin-bottom: 0;
        }
      }
    }
  }
}

.modal{
  &Header > div{
    font-size: 20px !important;
    padding-right: 30px;
  }
  &Icon{
    display: block;
    text-align: center;
    margin: 20px auto;
    width: 80px;
    height: 80px;
    color: #FFCC00;
}
}