.card-wrapper-s {
    position: relative;
    padding: 2px;
    width: 58px;
    height: 36px;
    border-radius: 6px;
    cursor: pointer;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
}

.card-wrapper-s .text {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    color: #000;
}

.card-wrapper-m {
    position: relative;
    padding: 4px;
    width: 72px;
    height: 44px;
    border-radius: 8px;
    cursor: pointer;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
}

.card-wrapper-m .text {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #000;
}

.card-wrapper-l {
    margin-top: 4px;
    position: relative;
    padding: 20px;
    width: 286px;
    height: 182px;
    border-radius: 20px;
    cursor: pointer;
}

.card-wrapper-l .text {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #000;
}

.selected {
    outline: 3px solid black;
}

.card-wrapper-l .status-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.4)
}

.card-wrapper-l .status-overlay svg {
    width: 36px;
    height: 36px;
}

.card-wrapper-m .status-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    justify-content: start;
    padding: 2px 2px 0 0;
    align-items: end;
    background-color: rgba(255, 255, 255, 0.4);
}

.card-wrapper-m .status-overlay svg {
    width: 16px;
    height: 16px;
}

.card-wrapper-m .status-overlay .text {
    display: none;
}

#virtual {
    position: absolute;
    top: 20px;
    left: 20px;
}

#chip {
    position: absolute;
    top: 54px;
    left: 26px;
}

.img {
    object-fit: cover;
}
